<template>
	<div id="login" :class="isLoggedOn ? 'input-heading' : 'input-heading notLoggedin'">
		<div class="login-status" v-if="isLoggedOn && !isMobile">
			<span>{{ displayName }}</span>
			<span>{{ casinoName }}</span>
			<button class="btn" type="button" title="Logout" @click="showLogoutModal()">Logout</button>
		</div>
		<div class="input-section" v-if="!isLoggedOn">
			<label for="casinoId">Please select a casino: </label>
			<div v-if="!casinoList?.length > 0" id="casino-list-loading">
				<span class="loading-icon"></span>
				Loading Casinos
			</div>
			<DropdownMenu v-if="casinoList.length > 0 && !isMobile" :casinoList="casinoList" :defaultCasinoId="casinoId" />
			<select title="Select Casino" v-if="!serverGettingCasinos && casinoList?.length > 0 && isMobile" v-model="casinoId" id="casinoId">
				<option v-for="casino in casinoList" v-bind:key="casino" :value="casino.id">
					{{ casino.name }}
				</option>
			</select>
			<input
				type="tel"
				pattern="[0-9]+"
				name="username"
				@keyup.enter="login()"
				v-model="username"
				placeholder="Phone Number"
				autocomplete="username"
			/>
			<input
				:type="unhidePassword ? 'text' : 'password'"
				name="password"
				@keyup.enter="login()"
				v-model="password"
				placeholder="Password"
				autocomplete="new-password"
			/>
			<span
				class="eye-span"
				@click="unhidePassword = unhidePassword ? false : true"
				:class="unhidePassword ? 'hidden' : ''"
				:title="unhidePassword ? 'Hide password' : 'Unhide password'"
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
					<path
						d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
					/>
					<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
				</svg>
			</span>
			<button class="btn" type="button" title="Login" @click="login()">Login</button>
		</div>
	</div>
	<dialog id="logout-dialog">
		<div>
			<p id="logout-text">
				{{`Are you sure you want to logout, ${this.displayName}`}}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="logout()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="closeModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";
import DropdownMenu from "@/components/DropdownMenu";

export default {
	name: "Login",
	components: {
		DropdownMenu,
	},
	props: {
		reporterState: Object,
		casinoList: Array,
		sessionRefreshCheck: Number,
		isMobile: Boolean,
	},
	watch: {
		isLoggedOn() {
			router.push("/");
		},
		reporterState: {
			handler(val, oldVal) {
				this.updateCasinoInfo();
			},
			deep: true,
		},
		sessionRefreshCheck: {
			handler(val, oldVal) {
				this.updateCasinoInfo();
			},
		},
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			isLoggedOn: false,
			accessToken: "",
			accessTokenExpiration: "",
			refreshToken: "",
			userPermissions: {
				userId: "",
				globalPermissions: "",
				sitePermissions: {},
			},
			sitePermissions: {},
			isCashier: false,
			isReporter: false,
			isSiteAdmin: false,
			isSysadmin: false,
			isHelpDesk: false,
			username: "",
			displayName: "",
			password: "",
			userId: "",
			casinoId: "",
			casinoName: "",
			casinoDescription: "",
			unhidePassword: false,
			inputDialog: null,
		};
	},
	created() {
		let thisInstance = this;
		this.eventBus.on("forceLogout", () => {
			thisInstance.logout();
		});
		this.eventBus.on("regularLogout", () => {
			thisInstance.showLogoutModal();
		});
		this.eventBus.on("casinoSelected", (payload) => {
			this.casinoId = payload.id;
		});
	},
	async mounted() {
		this.casinoId = this.casinoList.length > 0 ? 1 : null;
		this.isLoggedOn = this.reporterState?.isLoggedOn || false;
		this.userId = this.reporterState?.userId || "";
		this.username = this.reporterState?.username || "";
		this.displayName = this.reporterState?.displayName || "";
		this.userPermissions = this.reporterState?.userPermissions || {};
		this.sitePermissions = this.reporterState?.userPermissions?.sitePermissions || {};
		if (Object.keys(this.sitePermissions).length > 0) {
			this.isSiteAdmin = this.sitePermissions[this.reporterState.casinoId]?.includes("SiteAdmin") ? true : false;
			this.isCashier = this.sitePermissions[this.reporterState.casinoId]?.includes("Cashier") ? true : false;
			this.isReporter = this.sitePermissions[this.reporterState.casinoId]?.includes("Reporter") ? true : false;
		}
		// this.eventBus.emit("updateSession", this.reporterState);
		this.eventBus.emit("checkAndRefreshSession");
		this.inputDialog = document.getElementById("logout-dialog");
	},
	methods: {
		updateCasinoInfo() {
			if (this.reporterState?.isLoggedOn) {
				this.isLoggedOn = this.reporterState.isLoggedOn;
				this.casinoId = this.reporterState.casinoId;
				this.casinoName = this.reporterState.casinoName;
				this.sitePermissions = this.reporterState.userPermissions?.sitePermissions || {};
			} else {
				this.isLoggedOn = false;
				this.casinoId = null;
				this.casinoName = "";
				this.sitePermissions = {};
			}
		},
		async login() {
			let STATE = this.reporterState;

			if (this.username.match(/[^0-9]/)) {
				this.status.message = "Phone number must be numbers only.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}

			if (!this.username || !this.password) {
				this.status.message = "Please provide a valid phone number and password.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}

			if (!this.casinoId) {
				this.status.message = "Please choose the casino you would like to log into.";
				this.status.ok = false;
				this.eventBus.emit("updateStatus", this.status);
				return this.status;
			}

			try {
				let body = {
					phoneNumber: this.username.replace(/\D/g, ""),
					password: this.password,
				};

				let requestUrl = new URL("/api/v1/authentication/login", this.rabbitsfootHostUrl);
				let headerObj = new Headers();
				headerObj.append("Content-Type", "application/json; charset=utf-8");
				let request = new Request(requestUrl.toString(), {
					method: "POST",
					body: JSON.stringify(body),
					headers: headerObj,
				});
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = fetchStatus.code === 404 ? "Phone number or password is incorrect" : fetchStatus.message;
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}
				let dataJson = await response.json();

				let thisCasino = this.casinoList.filter((casino) => casino.id === this.casinoId)[0];

				STATE.casinoName = this.casinoName = thisCasino.name;
				STATE.casinoDescription = this.casinoDescription = thisCasino.description;
				STATE.casinoId = thisCasino.id;
				STATE.accessToken = dataJson.accessToken;
				STATE.accessTokenExpiration = dataJson.accessTokenExpiration;
				STATE.refreshToken = dataJson.refreshToken;
				STATE.userId = dataJson.userId;
				STATE.username = this.username;
				STATE.displayName = this.displayName = dataJson.displayName;
				STATE.isLoggedOn = this.isLoggedOn = true;
				this.userPermissions = dataJson.permissions;
				this.sitePermissions = this.userPermissions.sitePermissions;
				STATE.userPermissions = this.userPermissions;
				this.permissionsSuspended = dataJson.permissions.arePermissionsSuspended;
				this.isSysadmin = this.userPermissions?.globalPermissions?.includes("Sysadmin") ? true : false;
				this.isHelpDesk = this.userPermissions?.globalPermissions?.includes("HelpDesk") ? true : false;
				this.isSiteAdmin = this.sitePermissions?.[STATE.casinoId]?.includes("SiteAdmin") ? true : false;
				this.isCashier = this.sitePermissions?.[STATE.casinoId]?.includes("Cashier") ? true : false;
				this.isReporter = this.sitePermissions?.[STATE.casinoId]?.includes("Reporter") ? true : false;
				STATE.permissionsSuspended = this.permissionsSuspended;
				STATE.isSysadmin = this.isSysadmin;
				STATE.isSiteAdmin = this.isSiteAdmin;
				STATE.isHelpDesk = this.isHelpDesk;
				STATE.isCashier = this.isCashier;
				STATE.isReporter = this.isReporter;
				STATE.isSysAdminCashierOrHelpDesk = this.isSysadmin || this.isCashier || this.isCashier;
				STATE.loggedOnTimeCode = new Date().getTime();

				this.activeSession = STATE;
				this.password = "";
				this.unhidePassword = false;

				if (fetchStatus.code !== 200) this.eventBus.emit("updateStatus", fetchStatus);
				this.eventBus.emit("resetCurrencyToolConstructor", thisCasino.currencyInfo);
				this.eventBus.emit("checkAndRefreshSession");
				this.eventBus.emit("updateReporterState", STATE);
			} catch (e) {
				console.error(e);
			}
		},
		logout() {
			this.isLoggedOn = this.isCashier = this.isReporter = this.isSiteAdmin = false;
			this.displayName = this.username = "";
			this.userPermissions = this.activeSession = {};
			this.unhidePassword = false;
			this.eventBus.emit("toggleNavBar", true);
			this.eventBus.emit("updateReporterState", this.activeSession);
			this.closeModal();
			router.push("/");
		},

		showLogoutModal() {
			this.inputDialog.showModal();
		},

		closeModal() {
			this.inputDialog.close();
		}
	},
};
</script>

<!-- scoped attribute to limit CSS to this component only -->
<style scoped>
#login {
	position: fixed;
	z-index: 100;
}

#login.notLoggedin {
	position: fixed;
	width: 100%;
	height: 100%;
	display: grid;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	margin: auto;
	align-content: center;
	background-color: rgb(0 0 0 / 60%);
	backdrop-filter: blur(5px);
}

#login .input-section {
	display: flex;
	flex-direction: column;
	background: rgb(71 68 196 / 40%);
	padding: 30px;
	border-radius: 12px;
	box-shadow: inset -1px -1px 15px 1px rgb(13 28 37 / 50%);
	width: auto;
	margin: auto;
	position: relative;
}

.login-status {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: fixed;
	top: 0;
	right: 0;
	padding: 5px 10px;
	font-size: 0.625em;
	background-color: #32373f;
	border-radius: 0 0 0 0.4em;
	border: 1px #5b88c0 solid;
}

.login-status button {
	user-select: none;
	margin: 8px auto;
	padding: 5px;
}

label[for="casinoId"] {
	text-align: center;
	text-transform: uppercase;
	text-shadow: -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000;
}

#casino-list-loading {
	display: block;
	padding: 0;
	margin: auto;
}

button {
	margin: 5px auto 0;
	min-width: 6em;
	min-height: 2em;
}

#logout-dialog {
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}

::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}

#btn-container {
	display: flex;
	justify-content: center;
}

#logout-text {
	text-align: center;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.eye-span {
		top: 8px;
	}
}

.eye-span::after {
	content: "I";
	position: absolute;
	right: 8px;
	top: -7px;
	transform: rotate(45deg);
	font-size: 2em;
	font-weight: bold;
}

.eye-span {
	position: absolute;
	right: 45px;
	top: 130px;
	color: #000;
	cursor: pointer;
}

.eye-span .eye {
	width: 1.5em;
	height: auto;
}

.eye-span.hidden {
	color: #008000;
}

.eye-span.hidden::after {
	content: none;
}
</style>
